<template>
    <div class="layout-device">

        <!--s: Device menu-->
        <div class="device-menu">
<!--            <div class="menu-system">-->
<!--                <div class="menu-top">-->
<!--&lt;!&ndash;                    <h1 class="title">设备分类</h1>&ndash;&gt;-->
<!--&lt;!&ndash;                    <a href="javascript:" @click="onAddDevice"><Icon type="ios-add-circle-outline" /></a>&ndash;&gt;-->
<!--                </div>-->
<!--&lt;!&ndash;                <div class="menu-input">&ndash;&gt;-->
<!--&lt;!&ndash;                    <Input suffix="ios-search" placeholder="请输入" class="input"/>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--            </div>-->
            <div class="menu-list">
                <div class="list-item" :key="'child1'+key" v-for="(deviceList,key) in deviceListArray">
                    <div class="item-thead">
                        <a href="javascript:">
                            <Icon :type="deviceList.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon"  @click="deviceList.visible = !deviceList.visible"/>
                            <p :class="selectedMenuId && selectedMenuId[0] === deviceList.value && selectedMenuId[1] === ''?'active':''" @click="onLinks(deviceList.value,'','')">{{ deviceList.label }}</p>
                        </a>
                        <a href="javascript:" @click="onAddDevice" class="thead-btn"><Icon type="ios-add-circle-outline" /></a>
                    </div>
                    <div class="item-body" v-if="deviceList.visible">
                        <div class="item-child" :key="'child2'+key" v-for="(children,key) in deviceList.children">
                            <div class="child-thead" :style="children.children.length === 0?'padding-left:20px':''">
                                <a href="javascript:" class="href">
                                    <Icon v-if="children.children.length !== 0"  @click="children.visible = !children.visible" :type="children.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon" />
                                    <p :class="selectedMenuId && selectedMenuId[2] === children.value?'active':''" @click="onLinks(deviceList.value,children.value,children.value)">{{ children.label }}</p>
                                </a>
                                <div class="child-system">
                                    <Dropdown :transfer="true">
                                        <a href="javascript:">
                                            <Icon custom="more"/>
                                        </a>
                                        <DropdownMenu slot="list" class="system-menu">
                                            <DropdownItem>
                                                <a href="javascript:" @click="onUpdateDevice(children)">编辑</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a href="javascript:" @click="onDelDevice(children.value)">删除</a>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <a href="javascript:" class="href" @click="onChildAddDevice(children.value)"><Icon type="ios-add-circle-outline" class="icon"/></a>
                                </div>
                            </div>
                            <div class="child-body" v-if="children.visible">
                                <ul>
                                    <li :key="'child3'+key" v-for="(child,key) in children.children">
                                        <a href="javascript:" class="href" :class="selectedMenuId && selectedMenuId[2] === child.value?'active':''" @click="onLinks(deviceList.value,children.value,child.value)"><Icon type="ios-copy-outline" class="icon"/>{{ child.label }}</a>
                                        <div class="child-system">
                                            <Dropdown :transfer="true">
                                                <a href="javascript:">
                                                    <Icon custom="more"/>
                                                </a>
                                                <DropdownMenu slot="list" class="system-menu">
                                                    <DropdownItem>
                                                        <a href="javascript:" @click="onUpgradeDevice(child.value)">升级</a>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <a href="javascript:" @click="onUpdateDevice(child,children.value)">编辑</a>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <a href="javascript:" @click="onDelDevice(child.value)">删除</a>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--e: Device menu-->

        <!--s: Device content-->
        <div class="device-content" :style="menuVisible?'width: calc(100% - 560px);':'width: calc(100% - 310px);'">
            <router-view />
        </div>
        <!--e: Device content-->

        <Modal
            v-model="deviceModalVisible"
            :title="btnStatus?'添加设备分类':'修改设备分类'"
            footer-hide>
            <div class="device-modal">
                <span class="label">名称：</span>
                <Input v-model="deviceName" maxlength="10" show-word-limit placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-modal" v-if="!btnStatus">
                <span class="label">分类：</span>
                <Select v-model="deviceType" clearable  style="width:300px">
                    <Option v-for="(type,key) in deviceTypeList" v-show="deviceId !== type.value" :value="type.value" :key="key">{{ type.label }}</Option>
                </Select>
            </div>
            <div class="device-footer">
                <Button @click="deviceModalVisible = false">取 消</Button>
                <Button type="primary" :loading="deviceLoading" @click="onAddDeviceCheck">确 定</Button>
            </div>
        </Modal>

    </div>
</template>
<script>
import Device from './device'
export default Device
</script>
<style lang="less">
@import "device";
</style>
